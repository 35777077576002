<template>
  <div
    class="background"
    :class="{
      'lg-up': $vuetify.display.lgAndUp,
      md: $vuetify.display.md,
      sm: $vuetify.display.sm,
      xs: $vuetify.display.xs
    }"
  >
    <v-row class="content mx-auto">
      <v-col class="left" cols="12" md="8">
        <glib-panels-responsive
          :spec="{
            width: 'matchParent',
            childViews: spec.searchViews
          }"
        />

        <!-- <div style="padding-left: 20px; padding-right: 20px;">
          <select ref="select" class="category" @change="onSelect">
            <option value="" disabled selected>Popular job categories</option>
            <option v-for="option in spec.options" :value="option.url">
              {{ option.text }}
            </option>
          </select>
        </div> -->
      </v-col>

      <v-col class="right" cols="12" md="4">
        <glib-panels-responsive
          :spec="{
            width: 'matchParent',
            align: 'center',
            childViews: spec.previewViews
          }"
        />
      </v-col>
    </v-row>

    <div class="text-center" style="padding-bottom: 40px;">
      <!-- <glib-panels-responsive
        :spec="{
          width: 'matchParent',
          align: 'center',
          childViews: spec.searchViews
        }"
      /> -->
        <!-- <glib-component :spec="{ view: 'fields/text', text: spec.title }" /> -->
        <!-- <glib-component :spec="{ view: 'spacer', height: 2 }" />
        <glib-component :spec="{ view: 'h5', text: spec.subtitle, styleClasses: ['small'] }" /> -->

    </div>
  </div>
</template>

<script>
export default {
  props: {
    spec: { type: Object, required: true },
  },
  data: () => ({}),
  methods: {
    $ready() {},
    onSelect() {
      const properties = { url: this.$refs.select.value }
      GLib.http.load(properties, this);
      // window.location.href = this.$refs.select.value;
    }
  }
};
</script>

<style lang="scss" scoped>
.category {
  background-color: #000000;
  width: 100%;
  // height: 40px;
  color: #eded4e;
  padding: 10px 20px;
  border-radius: 6px;

  font-family: 'CeraGRMedium';
  font-size: 20px;
}

// .background {
//   background-color: #ecebeb;
//   background-size: cover;
//   background-position: bottom center;
//   background-repeat: no-repeat;
// }

.background {
  // background-color: #ffdb40;
  background-color: #eded4e;
  border-top: 2px solid #ffffff;
  // background-color: #ecebeb;
  // background-size: cover;
  // background-position: bottom center;
  // background-repeat: no-repeat;
}

.content {
  padding: 80px 0 50px 0;
  // text-align: center;
  // text-align: justify;

  h2 {
    font-size: 24px;
    color: #181818;
    line-height: 40px;
  }

  p {
    margin-top: 6px;
    color: #4c4c4c;
  }
}

.left, .right {
  padding-left: 20px;
  padding-right: 20px;
}

// .left {
//   h2 {
//     text-align: left;
//   }
// }

// .right {
//   h2 {
//     text-align: right;
//   }
// }

.lg-up {
  .content {
    max-width: 1025px !important;
  }
}
.md {
  .content {
    max-width: 900px !important;
  }
}
.sm {
  .content {
    max-width: 90vw !important;
  }
}
.xs {
  .content {
    width: 100vw !important;
    max-width: 480px;
  }

  h2 {
    font-size: 18px;
  }
}
</style>
